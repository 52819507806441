import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
  if (action.type === "LOADING_START") {
    return { ...state, isLoading: true };
  }
  if (action.type === "LOADING_START_EVENTS") {
    return { ...state, isLoadingEvents: true };
  }
  if (action.type === "LOADING_START_REWARDS") {
    return { ...state, isLoadingRewards: true };
  }
  if (action.type === "LOADING_END") {
    return { ...state, isLoading: false };
  }
  if (action.type === "FIELD_CHANGE") {
    return { ...state, [action.data.name]: action.data.val };
  }
  if (action.type === "LEAD_SUBMITTED") {
    return { ...state, isLoading: false, leadsubmitted: true };
  }
  if (action.type === "LEAD_SUBMITTED_RESET") {
    return { ...state, isLoading: false, leadsubmitted: false };
  }
  if (action.type === "SUBSCRIBE_LOADING_START") {
    return { ...state, isSubscribeLoading: true };
  }
  if (action.type === "SUBSCRIBE_SUBMITTED") {
    return {
      ...state,
      isSubscribeLoading: false,
      subSubmitted: true,
      subSubmittedData: action.data,
    };
  }
  if (action.type === "CAMPAIGNID_UPDATE") {
    return { ...state, campaignid: action.data };
  }
  if (action.type === "VIDEO_LANDING_START") {
    return {
      ...state,
      isVideoLandingLoading: true,
      isVideoLandingError: false,
    };
  }
  if (action.type === "VIDEO_LANDING_UPDATE") {
    return {
      ...state,
      videoLanding: action.data,
      isVideoLandingLoading: false,
      isVideoLandingError: false,
    };
  }
  if (action.type === "VIDEO_LANDING_ERROR") {
    return {
      ...state,
      isVideoLandingLoading: false,
      isVideoLandingError: true,
    };
  }
  if (action.type === "VIDEO_COLLECTION_START") {
    return {
      ...state,
      isVideoCollectionLoading: true,
      isVideoCollectionError: false,
    };
  }
  if (action.type === "VIDEO_COLLECTION_UPDATE") {
    return {
      ...state,
      videoCollection: action.data,
      isVideoCollectionLoading: false,
      isVideoCollectionError: false,
    };
  }
  if (action.type === "VIDEO_COLLECTION_ERROR") {
    return {
      ...state,
      isVideoCollectionLoading: false,
      isVideoCollectionError: true,
    };
  }
  if (action.type === "CONNECT_COLLECTION_START") {
    return {
      ...state,
      isConnectCollectionLoading: true,
      isConnectCollectionError: false,
    };
  }
  if (action.type === "CONNECT_COLLECTION_UPDATE") {
    if (action.additive) {
      return {
        ...state,
        connectCollection: { ...state.connectCollection, ...action.data },
        isConnectCollectionLoading: false,
        isConnectCollectionError: false,
      };
    } else {
      return {
        ...state,
        connectCollection: action.data,
        isConnectCollectionLoading: false,
        isConnectCollectionError: false,
      };
    }
  }
  if (action.type === "CONNECT_COLLECTION_ERROR") {
    return {
      ...state,
      isConnectCollectionLoading: false,
      isConnectCollectionError: true,
      rewardRedeemed: action.data,
    };
  }
  if (action.type === "CONNECT_COLLECTION_CLEAR") {
    return {
      ...state,
      connectCollection: {},
    };
  }

  if (action.type === "USERLEAD_SUBMITTED") {
    return {
      ...state,
      isLoading: false,
      userleadsubmitted: true,
      userleadstatus: action.data.status,
      userleadid: action.data.id,
    };
  }
  if (action.type === "USERLEAD_RESET") {
    return {
      ...state,
      isLoading: false,
      userleadsubmitted: false,
      userleadreset: action.data,
    };
  }

  if (action.type === "IMAGEUPLOAD_START") {
    console.log("IMAGEUPLOAD_START");
    return {
      ...state,
      isLoadingImageUpload: true,
    };
  }
  if (action.type === "IMAGEUPLOAD_PROGRESS") {
    console.log("IMAGEUPLOAD_PROGRESS");
    return {
      ...state,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val },
      },
    };
  }
  if (action.type === "IMAGEUPLOAD_FINISH") {
    console.log("IMAGEUPLOAD_FINISH");
    return {
      ...state,
      isLoadingImageUpload: false,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val, loc: action.data.loc },
      },
    };
  }
  if (action.type === "IMAGEUPLOAD_RESET") {
    console.log("IMAGEUPLOAD_RESET");
    return {
      ...state,
      isLoadingImageUpload: false,
      imageuploadprogress: {},
    };
  }
  if (action.type === "MUTED_REPLACE") {
    console.log("MUTED_REPLACE");
    return {
      ...state,
      isMuted: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_ADDRESS") {
    console.log("UPDATE_WALLET_ADDRESS");
    return {
      ...state,
      walletAddress: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_CHAIN") {
    console.log("UPDATE_WALLET_CHAIN");
    return {
      ...state,
      walletChainId: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_PROVIDER") {
    console.log("UPDATE_WALLET_PROVIDER");
    return {
      ...state,
      walletProvider: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_STATUS") {
    console.log("UPDATE_WALLET_STATUS");
    return {
      ...state,
      walletStatus: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_WEB3") {
    console.log("UPDATE_WALLET_WEB3");
    return {
      ...state,
      walletWeb3: action.data,
    };
  }
  if (action.type === "UPDATE_WALLET_SIGNER") {
    console.log("UPDATE_WALLET_SIGNER");
    return {
      ...state,
      walletSigner: action.data,
    };
  }
  if (action.type === "UPDATE_ACTIVE_NFT") {
    console.log("UPDATE_ACTIVE_NFT");
    return {
      ...state,
      activeNft: action.data,
    };
  }
  if (action.type === "UPDATE_ACTIVE_NFTMETADATA") {
    console.log("UPDATE_ACTIVE_NFTMETADATA");
    return {
      ...state,
      activeNftMetadata: action.data,
      isLoading: false,
    };
  }
  if (action.type === "UPDATE_ACTIVE_NFTEVENTS") {
    console.log("UPDATE_ACTIVE_NFTEVENTS");
    return {
      ...state,
      isLoadingEvents: false,
      activeNftEvents: action.data,
    };
  }
  if (action.type === "UPDATE_ACTIVE_ITEMGROUPS") {
    console.log("UPDATE_ACTIVE_ITEMGROUPS");
    return {
      ...state,
      isLoadingEvents: false,
      activeItemGroups: {
        ...state.activeItemGroups,
        [action.id]: action.data,
      },
    };
  }
  if (action.type === "UPDATE_ACTIVE_NFTREWARDS") {
    console.log("UPDATE_ACTIVE_NFTREWARDS");
    return {
      ...state,
      isLoadingRewards: false,
      activeNftRewards: action.data,
    };
  }
  if (action.type === "CLEAR_ACTIVE_NFTEVENTS") {
    console.log("CLEAR_ACTIVE_NFTEVENTS");
    return {
      ...state,
      activeNftEvents: [],
    };
  }
  if (action.type === "CLEAR_ACTIVE_NFTREWARDS") {
    console.log("CLEAR_ACTIVE_NFTEVENTS");
    return {
      ...state,
      activeNftRewards: [],
    };
  }
  if (action.type === "UPDATE_PAGEID") {
    console.log("UPDATE_PAGEID");
    return {
      ...state,
      pageId: action.data,
    };
  }

  if (action.type === "UPDATE_NFT_TRANSACTION_STATUS") {
    console.log("UPDATE_NFT_TRANSACTION_STATUS");
    return {
      ...state,
      nftTransactionStatus: action.data,
    };
  }
  if (action.type === "START_REDEMPTION_QRCODE") {
    console.log("START_REDEMPTION_QRCODE");
    return {
      ...state,
      isConnectCollectionLoading: true,
      redemptionQrCode: null,
    };
  }
  if (action.type === "UPDATE_REDEMPTION_QRCODE") {
    console.log("UPDATE_REDEMPTION_QRCODE");
    return {
      ...state,
      // isConnectCollectionLoading: false,
      redemptionQrCode: action.data,
    };
  }
  if (action.type === "START_REDEMPTION_REWARD") {
    console.log("START_REDEMPTION_REWARD");
    return {
      ...state,
      isConnectCollectionLoading: true,
      rewardRedeemed: null,
    };
  }
  if (action.type === "UPDATE_REDEMPTION_REWARD") {
    console.log("UPDATE_REDEMPTION_REWARD");
    console.log(action.data);
    return {
      ...state,
      isConnectCollectionLoading: false,
      rewardRedeemed: action.data,
    };
  }
  if (action.type === "LOADING_USERNFTS") {
    console.log("LOADING_USERNFTS");
    return {
      ...state,
      isLoadingUserNfts: true,
    };
  }
  if (action.type === "UPDATE_ALCHEMY_NFT_LIST") {
    console.log("UPDATE_ALCHEMY_NFT_LIST");
    return {
      ...state,
      userNftsAlchemy: action.data,
      preventRawReload: false,
      isLoadingUserNfts: false,
    };
  }
  if (action.type === "UPDATE_MARKETPLACE_TOKENS") {
    console.log("UPDATE_MARKETPLACE_TOKENS");
    console.log(action.data);
    return {
      ...state,
      marketplaceData: action.data,
    };
  }
  if (action.type === "RESET_MARKETPLACE_TOKENS") {
    console.log("RESET_MARKETPLACE_TOKENS");
    return {
      ...state,
      marketplaceData: [],
    };
  }
  if (action.type === "UPDATE_METADATA_ERROR") {
    console.log("UPDATE_METADATA_ERROR");
    return {
      ...state,
      errorMetadataLoad: action.data,
    };
  }
  if (action.type === "UPDATE_TOKENEVENT_DATEMODE") {
    console.log("UPDATE_TOKENEVENT_DATEMODE");
    console.log(action.data);
    return {
      ...state,
      tokenEventDateMode: action.data,
    };
  }
  if (action.type === "UPDATE_TOKENCOLLECTION_DATA") {
    console.log("UPDATE_TOKENCOLLECTION_DATA");
    console.log(action.data);
    return {
      ...state,
      tokenCollectionData: action.data,
      preventRawReload:
        action.data &&
        action.data.tokensoffchain &&
        action.data.tokensoffchain.length > 0
          ? false
          : true,
      isLoading: false,
    };
  }
  if (action.type === "COUNTRY_UPDATE") {
    console.log("COUNTRY_UPDATE");
    console.log(action.data);
    return {
      ...state,
      selectedCountry: action.data,
    };
  }

  if (action.type === "TOKEN_CONFIG_REPLACE") {
    console.log("TOKEN_CONFIG_REPLACE");
    console.log(action.data);
    return {
      ...state,
      tokenConfig: action.data,
    };
  }
  if (action.type === "UPDATE_LOGIN_MODE") {
    console.log("UPDATE_LOGIN_MODE");
    console.log(action.data);
    return {
      ...state,
      loginMode: action.data,
    };
  }

  return state;
};

const initialState = {
  isVideoLandingLoading: false,
  isVideoCollectionLoading: false,
  isVideoCollectionError: false,
  isConnectCollectionLoading: false,
  isConnectCollectionError: false,
  isSubscribeLoading: false,
  isLoading: false,
  leadsubmitted: false,
  subSubmitted: false,
  subSubmittedData: {},
  videoLanding: {},
  videoCollection: {},
  connectCollection: {},
  marketplaceData: [],
  userleadsubmitted: false,
  userleadstatus: null,
  userleadid: null,
  userleadreset: null,
  selectedCountry: "US",
  isLoadingImageUpload: false,
  isLoadingUserNfts: false,
  isLoadingEvents: false,
  isLoadingRewards: false,
  imageuploadprogress: {},
  isMuted: "initial",
  walletAddress: "",
  walletChainId: "",
  walletProvider: "",
  walletStatus: false,
  walletWeb3: null,
  walletSigner: null,
  nftTransactionStatus: {},
  activeNft: {},
  activeNftMetadata: {},
  activeNftEvents: [],
  activeNftRewards: [],
  activeItemGroups: {},
  pageId: null,
  redemptionQrCode: null,
  rewardRedeemed: {},
  userNftsAlchemy: [],
  errorMetadataLoad: false,
  tokenEventDateMode: "recorded", //recorded or attested
  tokenCollectionData: [],
  preventRawReload: false,
  tokenConfig: { tokenConfigSortOrder: "none" },
  loginMode: null,
};

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
