//React
import React, { useState } from "react";

//Gatsby
// import { Link } from "gatsby";

//Assets
import Logo from "../../images/svg/piconft-logo-04.svg";

const Nav = () => {
  const [openContext, setOpenContext] = useState(false);
  return (
    <nav class="navbar navbar-expand-lg navbar-light" id="navStreamlined">
      <div class="container">
        <a
          class="navbar-brand"
          aria-label="PicoNext Home"
          activeClassName="navbar-brand"
          href="/"
          // target="_blank"
        >
          <Logo />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          // data-toggle="collapse"
          // data-target="#navbarText"
          // aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenContext(!openContext)}
        >
          <i class="navbar-toggler-icon"></i>
        </button>
        <div
          class={`navbar-main navbar-collapse ${
            openContext ? "" : "collapse "
          }`}
          id="navbarText"
        >
          <ul class="navbar-nav mr-auto">
            {/* <li class="nav-item">
              <Link
                to="/about/capabilities/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Platform
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/about/features/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Features
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/industry/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Industries
              </Link>
            </li>
            <li class="nav-item">
              <a
                href={process.env.SUPPORT_DOMAIN}
                class="nav-link"
                target="_blank"
                activeClassName="nav-link active"
                rel="noopener noreferrer"
              >
                Support
              </a>
            </li> */}
          </ul>
          <div class="navbar-text d-flex flex-row align-items-center justify-content-center">
            <a
              href={process.env.CONSOLE_DOMAIN}
              class="console-link mr-1"
              activeClassName="nav-link active"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
            <a
              href="/request-invite/"
              class="nav-link"
              activeClassName="nav-link active"
              // target="_blank"
            >
              <div class="btn btn-primary btn-cta">Request invite</div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
